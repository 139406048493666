export const RATINGS = [
  {
    id: 1,
    rating: 3,
    comment:
      "Godfather ipsum dolor sit amet. It's a Sicilian message. It means Luca Brasi sleeps with the fishes. Te salut, Don Corleone.",
    author: "Michael Corleone",
  },
  {
    id: 2,
    rating: 2,
    comment:
      "Pie gummies icing shortbread shortbread soufflé. Icing marzipan marzipan gummi bears bear claw wafer cupcake chocolate bar",
    author: "Athbi",
  },
  {
    id: 3,
    rating: 5,
    comment: "If you were waiting for the opportune moment, that was it.",
    author: "Jack Sparrow",
  },
];
