import styled from "styled-components";

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${({ theme }) => theme.shadow};
  padding: 2em;
  @media (max-width: 750px) {
    flex-direction: column;
  }
`;

// Contact section
export const ContactWrapper = styled.div`
  width: 100%;
  padding: 1em;
`;

export const Image = styled.img`
  width: 8%;
`;

export const Description = styled.p`
  width: 100%;
  line-height: 38px;
  font-size: 0.875rem;
  padding: 1em 0;
`;

export const DescriptionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.secondary};
  text-decoration: underline;
`;

export const ContactInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const ContactInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1em 0;
  @media (max-width: 1024px) {
    padding: 0;
  }
`;

export const ContactIcon = styled.img`
  padding: 1em;
`;

export const ContactTitle = styled.p`
  font-size: 0.625rem;
  color: ${({ theme }) => theme.text};
`;

export const ContactSubtitle = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  color: ${({ theme }) => theme.secondary};
`;

export const TextWrapper = styled.div`
  padding: 1em 0;
`;

// Newletter section
export const NewsletterWrapper = styled.div`
  width: 100%;
  padding: 1em;
`;

export const NewsletterTitle = styled.p`
  font-size: 1.5rem;
`;

// Search Bar
export const EmailInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.text};
  border-radius: 20px;
  padding: 0 0.5em;
  @media (max-width: 750px) {
    width: 60%;
  }
  @media (max-width: 450px) {
    width: 80%;
  }
`;

export const EmailInput = styled.input`
  background-color: transparent;
  width: 30vw;
  border: 0;
  font-size: 0.75rem;
  color: #ffffff;
  padding: 0 0.5em;

  :focus {
    outline: none;
  }
`;

export const SubmitButton = styled.button`
  background-color: #5ac994;
  border: none;
  border-radius: 20px;
  color: #ffffff;
  font-size: 0.75rem;
  padding: 0.5em 1em;
`;

// Social Media Icons

export const SocialMediaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1em;
  justify-content: right;
`;

export const SocialMediaLink = styled.a`
  text-decoration: none;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  margin: 1em 0.5em;
`;

export const CreditWrapper = styled.div`
  text-align: right;
`;

export const CreditText = styled.p`
  font-size: 0.75rem;
  color: #5ac994;
  padding: 1em;
`;
