// Component
import starImg from "../../assets/star-solid.svg";

// Styles
import {
  SlideWrapper,
  Image,
  Author,
  Paragraph,
  ImageWrapper,
} from "../../Styles/ratings";

const Slide = ({ rating, author, comment }) => {
  const stars = Array(rating).fill(null);
  const starsArrays = stars.map(() => <Image src={starImg} alt="star" />);
  return (
    <SlideWrapper>
      <div>
        <Paragraph>"{comment}"</Paragraph>
        <Author>{author}</Author>
        <ImageWrapper>{starsArrays}</ImageWrapper>
      </div>
    </SlideWrapper>
  );
};

export default Slide;
