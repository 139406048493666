export const theme = {
  lightTheme: {
    body1: "#FFFFFF",
    body2: "#9CEAB4",
    text: "#333333",
    shadow: "rgba(255, 255, 255, 0.5)",
    hover: "rgba(255, 255, 255, 1)",
    secondary: "#229310",
  },
  darkTheme: {
    body1: "#161B29",
    body2: "#124143",
    text: "#FFFFFF",
    shadow: "rgba(0, 0, 0, 0.25)",
    hover: "rgba(0, 0, 0, 0.8)",
    secondary: "#c0f7b8",
  },
};
