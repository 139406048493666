// Media
import Snow from "../assets/carousal/nature-snow.svg";
import Forest from "../assets/carousal/nature-forest.svg";
import Ocean from "../assets/carousal/nature-ocean.svg";
import Road from "../assets/carousal/nature-road.svg";

export const SLIDE = [
  {
    id: 1,
    image: Snow,
    alt: "Snow Lanscape",
    title: "Header 1",
    paragraph: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
			sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
			 Ut enim ad minim veniam, quis nostrud exercitation ullamco 
			 laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure 
			 dolor in reprehenderit in voluptate velit esse cillum dolore eu 
			 fugiat nulla pariatur.`,
  },
  {
    id: 2,
    image: Forest,
    alt: "Forest Landscape",
    title: "Header 2",
    paragraph: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
		sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
		 Ut enim ad minim veniam, quis nostrud exercitation ullamco 
		 laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure 
		 dolor in reprehenderit in voluptate velit esse cillum dolore eu 
		 fugiat nulla pariatur.`,
  },
  {
    id: 3,
    image: Ocean,
    alt: "Ocean Landscape",
    title: "Header 3",
    paragraph: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
		sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
		 Ut enim ad minim veniam, quis nostrud exercitation ullamco 
		 laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure 
		 dolor in reprehenderit in voluptate velit esse cillum dolore eu 
		 fugiat nulla pariatur.`,
  },
  {
    id: 4,
    image: Road,
    alt: "Road Landscape",
    title: "Header 4",
    paragraph: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
		sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
		 Ut enim ad minim veniam, quis nostrud exercitation ullamco 
		 laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure 
		 dolor in reprehenderit in voluptate velit esse cillum dolore eu 
		 fugiat nulla pariatur.`,
  },
];
