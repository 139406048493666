import styled from "styled-components";

// Navbar
export const NavbarWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  @media (max-width: 769px) {
    justify-content: space-between;
  }
`;

// Menu
export const MenuWarpper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 2.125em;
  z-index: 1;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MenuLogo = styled.img`
  width: 50%;
`;

export const MenuButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
  }
`;

export const MenuButton = styled.a`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
  font-size: 0.875rem;
  padding: 1em 1em;
`;

// Search Bar
export const Searchbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 30vw;
  height: 5vh;
  border: 1px solid ${({ theme }) => theme.text};
  border-radius: 20px;
  padding: 0 8px;
`;

export const SearchbarInput = styled.input`
  background-color: transparent;
  width: 30vw;
  border: 0;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.text};
  padding: 0 0.5em;

  :focus {
    outline: none;
  }
`;

export const SerachbarIcon = styled.img`
  fill: ${({ theme }) => theme.text};
`;

// Theme Button
export const ThemeButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`;

// Burger Menu
export const BurgerMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1.125em;
  z-index: 1;
  @media (min-width: 769px) {
    display: none;
  }
`;

// Drawer
export const Drawer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  z-index: 2;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 40vw;
  background: ${({ theme }) => theme.hover};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.text};
  font-size: 1.2rem;
  padding: 1em;
`;

export const UpLink = styled.button`
  background-color: transparent;
  border: none;
  padding: 1em;
  position: fixed;
  text-decoration: none;
  top: 90%;
  left: 92%;
  z-index: 1;
  @media (max-width: 768px) {
    top: 90%;
    left: 80%;
  }
`;
