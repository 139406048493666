import React from "react";

// Media
import Logo from "../../assets/logo.svg";
import LogoDark from "../../assets/logo-dark.svg";
import searchLogo from "../../assets/search.svg";
import searchLogoDark from "../../assets/dark-search.svg";

// Style
import {
  MenuWarpper,
  MenuButtonWrapper,
  MenuButton,
  MenuLogo,
  Searchbar,
  SerachbarIcon,
  SearchbarInput,
} from "../../Styles/navbar";

const Menu = ({ currentTheme }) => {
  return (
    <MenuWarpper>
      <div>
        <MenuLogo
          src={currentTheme === "lightTheme" ? LogoDark : Logo}
          alt="Yin Yang logo"
        />
      </div>
      <Searchbar>
        <SerachbarIcon
          src={currentTheme === "lightTheme" ? searchLogoDark : searchLogo}
          alt="Search Icon"
        />
        <SearchbarInput type="text" placeholder="Search..." />
      </Searchbar>
      <MenuButtonWrapper>
        <MenuButton href="#home">Home</MenuButton>
        <MenuButton href="#about-us">About us</MenuButton>
        <MenuButton href="#products">Products</MenuButton>
        <MenuButton href="#ratings">Ratings</MenuButton>
      </MenuButtonWrapper>
    </MenuWarpper>
  );
};

export default Menu;
