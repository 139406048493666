import React from "react";

// Styles
import {
  Card,
  Header,
  Image,
  TextWrapper,
  SubHeader,
} from "../../Styles/aboutus";

const TeamMember = ({ title, paragraph, image }) => {
  return (
    <Card>
      <Image src={image} />
      <TextWrapper>
        <Header>{title}</Header>
        <SubHeader>{paragraph}</SubHeader>
      </TextWrapper>
    </Card>
  );
};

export default TeamMember;
