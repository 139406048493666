import styled from "styled-components";

export const RatingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const SlideShow = styled.div`
  margin: 1em auto;
  overflow: hidden;
  min-height: 30vh;
  width: 100%;
`;

export const Arrow = styled.img`
  cursor: pointer;
  height: 2rem;
  position: absolute;
  z-index: 1;
  padding: 3em 0;
  &.right {
    left: calc(100% - 2em);
  }
  :active {
    transform: translateY(2px);
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Image = styled.img`
  height: 0.875rem;
  margin: 0 0.5em;
`;

export const SlideWrapper = styled.div`
  align-content: center;
  background: ${({ theme }) => theme.shadow};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 2em;
  white-space: normal;
  width: 84vw;
  height: 100%;
  justify-items: center;
  @media (max-width: 768px) {
    width: 75vw;
  }
`;

export const Title = styled.p`
  font-size: 1.5rem;
  padding: 0.5em 0;
`;

export const Author = styled.p`
  font-size: 0.75rem;
  text-align: center;
`;

export const Paragraph = styled.p`
  font-size: 1rem;
  margin: 0 auto 1em;
  text-align: center;
`;
