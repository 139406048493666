import React from "react";

// Media
import Logo from "../../assets/logo.svg";
import LogoDark from "../../assets/logo-dark.svg";
import Mail from "../../assets/footer/mail.svg";
import Phone from "../../assets/footer/phone.svg";

// Style
import {
  ContactIcon,
  ContactInfo,
  ContactInfoWrapper,
  ContactWrapper,
  ContactSubtitle,
  ContactTitle,
  Description,
  DescriptionButton,
  Image,
  TextWrapper,
} from "../../Styles/footer";

const ContactSection = ({ currentTheme }) => {
  const [visibility, setVisibility] = React.useState(false);

  const handleClick = () => {
    setVisibility(!visibility);
  };

  return (
    <ContactWrapper>
      <div>
        <Image
          src={currentTheme === "lightTheme" ? LogoDark : Logo}
          alt="Yin Yang symbol"
        />
      </div>
      <div>
        <Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam
          {visibility ? (
            <p>
              quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
              commodo consequat. Duis aute irure dolor in reprehenderit in
              voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
              officia deserunt mollit anim id est laborum
            </p>
          ) : null}
          <DescriptionButton onClick={handleClick}>
            {visibility ? "Read Less" : "Read More"}
          </DescriptionButton>
        </Description>
      </div>
      <ContactInfoWrapper>
        <ContactInfo>
          <ContactIcon src={Phone} alt="Phone icon" />
          <TextWrapper>
            <ContactTitle>Have a question?</ContactTitle>
            <ContactSubtitle
              onClick={() => (window.location = "tel:407-965-7074")}
            >
              407-965-7074
            </ContactSubtitle>
          </TextWrapper>
        </ContactInfo>
        <ContactInfo>
          <ContactIcon src={Mail} alt="Mail icon" />
          <TextWrapper>
            <ContactTitle>Contact us at</ContactTitle>
            <ContactSubtitle
              onClick={() => (window.location = "mailto:NoMail@outlook.com")}
            >
              NoMail@outlook.com
            </ContactSubtitle>
          </TextWrapper>
        </ContactInfo>
      </ContactInfoWrapper>
    </ContactWrapper>
  );
};

export default ContactSection;
