import React from "react";

// Style
import {
  SlideWrapper,
  Image,
  Title,
  Paragraph,
  ImageWrapper,
} from "../../Styles/carousel";

const Slide = ({ image, alt, title, paragraph }) => {
  return (
    <SlideWrapper>
      <div>
        <Title>{title}</Title>
        <Paragraph>{paragraph}</Paragraph>
      </div>
      <ImageWrapper>
        <Image src={image} alt={alt} />
      </ImageWrapper>
    </SlideWrapper>
  );
};

export default Slide;
