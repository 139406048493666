// Media
import person1 from "../assets/team-members/person1.svg";
import person2 from "../assets/team-members/person2.svg";
import person3 from "../assets/team-members/person3.svg";
import person4 from "../assets/team-members/person4.svg";

export const TEAM_MEMBERS = [
  {
    id: 1,
    title: "Micheal Jackson",
    paragraph: `A chief executive officer (CEO) is the highest-ranking
     executive in a company. Broadly speaking, a chief executive officer’s 
     primary responsibilities.`,
    image: person1,
  },
  {
    id: 2,
    title: "Johna miller",
    paragraph: `Sed ut perspiciatis unde omnis iste natus
     error sit voluptatem accusantium doloremque laudantium,
      totam rem aperiam, eaque ipsa quae ab illo inventore 
      veritatis et quasi architecto beatae vitae`,
    image: person2,
  },
  {
    id: 3,
    title: "Doneta Miller",
    paragraph: `Lorem ipsum dolor sit amet, consectetur 
    adipiscing elit, sed do eiusmod tempor incididunt ut 
    labore et dolore magna aliqua. Ut enim ad minim veniam,
    quis nostrud exercitation`,
    image: person3,
  },
  {
    id: 4,
    title: "Alex Perry",
    paragraph: `ullamco laboris nisi ut aliquip ex ea 
    commodo consequat. Duis aute irure dolor in reprehenderit 
    in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`,
    image: person4,
  },
];
