import React from "react";

// Media
import burgerMenu from "../../assets/burgerMenu.svg";
import burgerMenuDark from "../../assets/burgerMenuDark.svg";

// Style
import {
  BurgerMenuWrapper,
  CloseButton,
  Drawer,
  MenuButton,
  MenuButtonWrapper,
} from "../../Styles/navbar";

const BurgerMenu = ({ currentTheme }) => {
  const [appearance, setAppearance] = React.useState(false);

  const handleOpen = () => {
    setAppearance(true);
  };

  const handleClose = () => {
    setAppearance(false);
  };

  return (
    <BurgerMenuWrapper>
      {!appearance ? (
        <img
          src={currentTheme === "lightTheme" ? burgerMenuDark : burgerMenu}
          onClick={handleOpen}
          alt="Burger Menu"
        />
      ) : null}

      {appearance ? (
        <Drawer>
          <MenuButtonWrapper>
            <CloseButton onClick={handleClose}>&#10005;</CloseButton>
            <MenuButton href="#home">Home</MenuButton>
            <MenuButton href="#about-us">About us</MenuButton>
            <MenuButton href="#products">Products</MenuButton>
            <MenuButton href="#ratings">Ratings</MenuButton>
          </MenuButtonWrapper>
        </Drawer>
      ) : null}
    </BurgerMenuWrapper>
  );
};

export default BurgerMenu;
