import React from "react";

// Media
import email from "../../assets/footer/email.svg";
import Twitter from "../../assets/footer/twitter.svg";
import Instagram from "../../assets/footer/instagram.svg";
import Facebook from "../../assets/footer/facebook.svg";

// Styles
import {
  CreditWrapper,
  CreditText,
  Description,
  EmailInput,
  EmailInputWrapper,
  NewsletterTitle,
  NewsletterWrapper,
  SubmitButton,
  SocialMediaLink,
  SocialMediaWrapper,
} from "../../Styles/footer";

const NewsLetterSection = () => {
  return (
    <NewsletterWrapper>
      <NewsletterTitle>Newsletter</NewsletterTitle>
      <Description>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.
      </Description>
      <EmailInputWrapper>
        <img src={email} alt="Email Icon" />
        <EmailInput type="text" placeholder="Enter your email" />
        <SubmitButton>Submit</SubmitButton>
      </EmailInputWrapper>
      <SocialMediaWrapper>
        <SocialMediaLink href="https://twitter.com/?lang=en">
          <img src={Twitter} alt="Twitter icon" />
        </SocialMediaLink>
        <SocialMediaLink href="https://www.instagram.com/">
          <img src={Instagram} alt="Instagram icon" />
        </SocialMediaLink>
        <SocialMediaLink href="https://www.facebook.com/">
          <img src={Facebook} alt="Facebook icon" />
        </SocialMediaLink>
      </SocialMediaWrapper>
      <CreditWrapper>
        <CreditText>© 1990-1998, All rights reserved</CreditText>
      </CreditWrapper>
    </NewsletterWrapper>
  );
};

export default NewsLetterSection;
