import styled from "styled-components";

// Slide
export const SlideWrapper = styled.div`
  align-content: center;
  display: flex;
  justify-content: center;
  margin: 5em auto 0;
  padding: 1em;
  white-space: normal;
  width: 90vw;
  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }
`;

export const ImageWrapper = styled.div`
  margin: auto;
`;

export const Image = styled.img`
  height: 44vh;
  width: 50vw;
  @media (max-width: 1024px) {
    width: 90vw;
  }
`;

export const Title = styled.p`
  font-size: 2rem;
`;

export const Paragraph = styled.p`
  font-size: 0.875rem;
  line-height: 38px;
  text-align: justify;
`;

// Carousel
export const SlideShow = styled.div`
  background: ${({ theme }) => theme.shadow};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
`;

export const SlideShowSlider = styled.div`
  white-space: nowrap;
  transition: ease 1000ms;
`;

export const Slides = styled.div`
  display: inline-block;
  width: 100%;
`;

// Slide show dots
export const SlideShowDotWrapper = styled.div`
  margin: 0 auto 2em;
  text-align: center;
  width: fit-content;
`;

export const SlideShowDot = styled.div`
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0.9em 0.2em 0;
  background-color: ${(props) => (props.active ? "#5AC994" : "#c4c4c4")};
`;
