import styled from "styled-components";

export const ProductWrapper = styled.div`
  background-color: ${({ theme }) => theme.shadow};
  margin: 1em 0;
  padding: 1em 0;
  width: 100%;
`;

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-content: center;
`;

export const ProductCard = styled.div`
  background-color: ${({ theme }) => theme.shadow};
  margin: 1em auto;
  padding: 1em;
  width: 75%;
  &:hover {
    background-color: ${({ theme }) => theme.hover};
    box-shadow: 0px 20px 20px 0px #00000040;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1em 0;
`;
export const Image = styled.img`
  width: 80%;
`;

export const Button = styled.button`
  background-color: #5ac994;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  padding: 0.75em 0.5em;
  cursor: pointer;
  &:hover {
    background-color: #78e7b2;
  }
`;

export const ContentWrapper = styled.div`
  padding: 1em;
`;

export const Title = styled.p`
  font-size: 0.875rem;
`;

export const Paragraph = styled.p`
  font-size: 0.875rem;
`;
