import React from "react";

// Components
import ContactSection from "./ContactSection";
import NewsLetterSection from "./NewsLetterSection";

// Styles
import { FooterWrapper } from "../../Styles/footer";

const Footer = ({ currentTheme }) => {
  return (
    <FooterWrapper>
      <ContactSection currentTheme={currentTheme} />
      <NewsLetterSection />
    </FooterWrapper>
  );
};

export default Footer;
