import React from "react";

// Components
import TeamMember from "./TeamMember";

// Data
import { TEAM_MEMBERS } from "../../data/teamMembers";

//  Styles
import { Paragraph, CardsGrid, AboutusWrapper } from "../../Styles/aboutus";
import { Title } from "../../Styles/globalStyles";

const AboutUs = () => {
  return (
    <AboutusWrapper id="about-us">
      <Title>About us</Title>
      <Paragraph>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim
      </Paragraph>
      <CardsGrid>
        {TEAM_MEMBERS.map(({ id, ...data }) => (
          <TeamMember key={id} {...data} />
        ))}
      </CardsGrid>
    </AboutusWrapper>
  );
};

export default AboutUs;
