import React, { useState } from "react";

// Component
import BurgerMenu from "./BurgerMenu";
import Menu from "./Menu";

// Media
import darkMode from "../../assets/dark-mode.svg";
import lightMode from "../../assets/light-mode.svg";
import UpArrow from "../../assets/up-arrow.svg";

// Styles
import { NavbarWrapper, UpLink, ThemeButton } from "../../Styles/navbar";

const Navbar = ({ currentTheme, toggleTheme }) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <NavbarWrapper>
      <Menu currentTheme={currentTheme} />
      <BurgerMenu currentTheme={currentTheme} />
      {visible ? (
        <UpLink onClick={scrollToTop}>
          <img src={UpArrow} alt="Up arrow icon" />
        </UpLink>
      ) : null}
      <ThemeButton onClick={toggleTheme}>
        <img
          src={currentTheme === "lightTheme" ? darkMode : lightMode}
          alt="Theme Icon"
        />
      </ThemeButton>
    </NavbarWrapper>
  );
};

export default Navbar;
