import React, { useState, useEffect, useRef } from "react";

// Component
import Slide from "./Slide";
import left from "../../assets/left-arrow.svg";
import right from "../../assets/right-arrow.svg";

// Data
import { RATINGS } from "../../data/ratings";

// Styles
import { Slides, SlideShowSlider } from "../../Styles/carousel";

import { RatingsWrapper, SlideShow, Title, Arrow } from "../../Styles/ratings";

const delay = 10000;

const Ratings = () => {
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === RATINGS.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );
    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <RatingsWrapper id="ratings">
      <Title>Ratings</Title>
      <SlideShow>
        <Arrow
          src={left}
          onClick={() => {
            setIndex((prevIndex) =>
              prevIndex === RATINGS.length - 1 ? prevIndex - 1 : 0
            );
          }}
        />

        <Arrow
          className="right"
          src={right}
          onClick={() => {
            setIndex((prevIndex) =>
              prevIndex === 1 || prevIndex === RATINGS.length - 1
                ? RATINGS.length - 1
                : prevIndex + 1
            );
          }}
        />

        <SlideShowSlider
          style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
        >
          {RATINGS.map(({ id, ...data }) => (
            <Slides key={id}>
              <Slide key={id} {...data} />
            </Slides>
          ))}
        </SlideShowSlider>
      </SlideShow>
    </RatingsWrapper>
  );
};

export default Ratings;
