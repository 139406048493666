import React from "react";

// Style
import {
  Button,
  ContentWrapper,
  Image,
  ImageWrapper,
  Paragraph,
  ProductCard,
  Title,
} from "../../Styles/products";

const Product = ({ alt, image, paragraph, title }) => {
  return (
    <ProductCard>
      <ImageWrapper>
        <Image src={image} alt={alt} />
      </ImageWrapper>
      <ContentWrapper>
        <Title>{title}</Title>
        <Paragraph>{paragraph}</Paragraph>
        <Button>Learn More</Button>
      </ContentWrapper>
    </ProductCard>
  );
};

export default Product;
