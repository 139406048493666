import React from "react";

// Components
import Product from "./Product";

// Data
import { PRODUCTS } from "../../data/products";

// Style
import { Title } from "../../Styles/globalStyles";
import { ProductWrapper, CardWrapper } from "../../Styles/products";

const Products = () => {
  return (
    <ProductWrapper id="products">
      <Title>Product</Title>
      <CardWrapper>
        {PRODUCTS.map(({ id, ...data }) => (
          <Product key={id} {...data} />
        ))}
      </CardWrapper>
    </ProductWrapper>
  );
};

export default Products;
