import styled from "styled-components";

export const AboutusWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em 0;
`;

export const Paragraph = styled.p`
  font-size: 0.875rem;
  line-height: 38px;
  text-align: center;
  width: 50vw;
`;

export const CardsGrid = styled.div`
  align-items: baseline;
  display: grid;
  gap: 10vh 5vw;
  grid-template-columns: auto auto;
  justify-content: center;
  @media (max-width: 568px) {
    grid-template-columns: auto;
  }
`;

export const Card = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.shadow};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 0.5em 2em;
  width: 36vw;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  @media (max-width: 568px) {
    flex-direction: row;
    width: 75vw;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 1.5em;
  width: 100%;
  line-height: 28px;
`;

export const Image = styled.img`
  height: 15vh;
  position: relative;
`;

export const Header = styled.p`
  font-size: 1.25rem;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const SubHeader = styled.p`
  font-size: 0.875rem;
  width: fit-content;
  margin: 0;
`;
