//  Media
import Ocean from "../assets/products/ocean.svg";
import Hills from "../assets/products/hills.svg";
import Beach from "../assets/products/beach.svg";

export const PRODUCTS = [
  {
    id: 1,
    image: Ocean,
    alt: "The ocean",
    title: "The ocean",
    paragraph: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
     Ut enim ad minim veniam.`,
  },
  {
    id: 2,
    image: Hills,
    alt: "The Hills",
    title: "The Hills",
    paragraph: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
     Ut enim ad minim veniam.`,
  },
  {
    id: 3,
    image: Beach,
    alt: "The beach",
    title: "The beach",
    paragraph: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
     Ut enim ad minim veniam.`,
  },
];
