import React, { useState, useEffect, useRef } from "react";

// Components
import Slide from "./Slide";

// Data
import { SLIDE } from "../../data/slides";

// Style
import {
  Slides,
  SlideShow,
  SlideShowSlider,
  SlideShowDot,
  SlideShowDotWrapper,
} from "../../Styles/carousel";

const delay = 10000;

const Carousel = () => {
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === SLIDE.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );
    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <SlideShow>
      <SlideShowSlider
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {SLIDE.map(({ id, ...data }) => (
          <Slides key={id}>
            <Slide key={id} {...data} />
          </Slides>
        ))}
      </SlideShowSlider>
      <SlideShowDotWrapper>
        {SLIDE.map((_, id) =>
          index === id ? (
            <SlideShowDot key={id} active />
          ) : (
            <SlideShowDot
              key={id}
              onClick={() => {
                setIndex(id);
              }}
            />
          )
        )}
      </SlideShowDotWrapper>
    </SlideShow>
  );
};

export default Carousel;
