import { useState } from "react";

// Components
import AboutUs from "./Components/AboutUs/";
import Carousel from "./Components/Carousel/";
import Footer from "./Components/Footer/";
import Navbar from "./Components/Navbar/";
import Products from "./Components/Products/";
import Ratings from "./Components/Ratings/";

// Style
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "./Styles/globalStyles";
import { theme } from "./Styles/theme";

function App() {
  const [currentTheme, setTheme] = useState("darkTheme");

  const toggleTheme = () => {
    currentTheme === "lightTheme"
      ? setTheme("darkTheme")
      : setTheme("lightTheme");
  };

  return (
    <ThemeProvider theme={theme[currentTheme]}>
      <>
        <GlobalStyle />
        <Navbar currentTheme={currentTheme} toggleTheme={toggleTheme} />
        <Carousel />
        <AboutUs />
        <Products />
        <Ratings />
        <Footer currentTheme={currentTheme} />
      </>
    </ThemeProvider>
  );
}

export default App;
