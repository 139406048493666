import styled, { createGlobalStyle, keyframes } from "styled-components";

const BgAnimation = keyframes`
 0% {
     background-position:left ;
 }
 100% {
     background-position: right;
 }
`;

export const GlobalStyle = createGlobalStyle`
body{
    background-image: linear-gradient(130deg, ${({ theme }) =>
      theme.body1} 18.21%, ${({ theme }) => theme.body2} 65.65%);
    background-size: 300%;
    animation: ${BgAnimation} 10s infinite alternate;
    color: ${({ theme }) => theme.text};
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
`;

export const Title = styled.p`
  font-size: 1.5rem;
  text-align: center;
`;
